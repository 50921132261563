import { Link } from 'react-router-dom';
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';

import about from "./img/about.png";

function textInfo(t) {
  return (
    <div className="container" style={{"overflow-y": "auto"}}>
      <div className="text-center justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2 text-dark"> {t('home.about.s1title')}</h1>
      </div>

      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <img src={about} alt="Emergency" class="img-thumbnail img-fluid" style={{ "width": "250px" }} />
      </div>

      <hr className="my-4" />
      <div class="container">
        <div class="row">

          <div class="col-sm">
            <h3 className="centertxt"> {t('home.about.s1col1title')}</h3>
            <p>
              {t('home.about.s1col1desc')}
            </p>
            <br />
          </div>

          <div class="col-sm">
            <h3 className="centertxt"> {t('home.about.s1col2title')}</h3>
            <p>
              {t('home.about.s1col2desc')}
            </p>
            <br />
          </div>

          <div class="col-sm">
            <h3 className="centertxt"> {t('home.about.s1col3title')}</h3>
            <p>
              {t('home.about.s1col3desc')}
            </p>
            <br />
          </div>

        </div>
      </div>

      <hr className="my-4" />
      <div class="container">
        <div class="row">

          <div class="col-sm" style={{ "max-width": "40rem" }}>
            <h3 className="centertxt">{t('home.about.s1col2title')}</h3>
            <ul>
              <li><strong>{t('home.about.s2col1l1txt')}</strong>{t('home.about.s2col1l1desc')}</li>
              <li><strong>{t('home.about.s2col1l2txt')}</strong>{t('home.about.s2col1l2desc')}</li>
              <li><strong>{t('home.about.s2col1l3txt')}</strong>{t('home.about.s2col1l3desc')}</li>
              <li><strong>{t('home.about.s2col1l4txt')}</strong>{t('home.about.s2col1l4desc')}</li>
            </ul>
            <br />
          </div>

          <div class="col-sm" style={{ "max-width": "40rem" }}>
            <h3 className="centertxt">{t('home.about.s2col2title')}</h3>
            <p>
              {t('home.about.s2col2desc')}
            </p>
            <br />
          </div>

        </div>
      </div>

    </div>

  )
}


function About() {
  const { t } = useTranslation();

  return (
    <div >

      <main>
        <div class="d-flex flex-column flex-shrink-0 p-3 bg-light" style={{ minWidth: "120px", maxWidth: "20%" }}>
          <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
          </a>
          <ul class="nav flex-column mb-auto">
            <li class="nav-item">
              <Link class="nav-link active" to="/home/overview" >
                {t('barside.overview')}
              </Link >
            </li>
            <li class="nav-item">
              <Link class="nav-link active" to="/home/about">
                {t('barside.about')}
              </Link >
            </li>
            <li class="nav-item">
              <Link class="nav-link active" to="/home/faq"> {t('barside.faq')}</Link >
            </li>
          </ul>
        </div>
        {textInfo(t)}
      </main>

      <Footer />
    </div>
  );
}

export default About;
