
function Footer() {
  return (
    <footer>
      <div className="container">
        <hr className="my-1" />
        <div className="text-center">
          <br />
          <p>2024 3vidence.com</p>
          <p>Inspired by the beauty of mathematics</p>
          <p>
            Ramanujan-Hardy Numbers:
            <a href="https://oeis.org/A001235" target="_blank" rel="noopener noreferrer">
              [1729, 4104, 13832, 20683, 32832, 39312, 40033, 46683, 64232]
            </a>
          </p>
          <p>A tribute to mathematical elegance and innovation</p>
        </div>
      </div>
    </footer>


  );
}

export default Footer;
