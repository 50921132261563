import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const CookieBanner = () => {
  const { t } = useTranslation();

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const isAccepted = localStorage.getItem('cookiesAccepted');
    if (!isAccepted) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="position-fixed bottom-0 start-0 end-0 bg-light p-3 text-center border-top" style={{ zIndex: 1050 }}>
      <p className="mb-2"><em>{t('privacy.msg')}</em> <br/>
        <a href="/privacypolicy" >{t('privacy.txt1')}</a> <br/>
        <a href="/termsofservice" >{t('privacy.txt2')}</a> <br/>
      </p>
      <button onClick={handleAccept} className="btn btn-secondary">{t('privacy.btn')}</button>
    </div>
  );
};

export default CookieBanner;
