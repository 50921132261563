import { useState } from 'react';

export function useToken() {
  const getToken = () => {
    const tokenString = sessionStorage.getItem('token')
    const userToken = tokenString
    return userToken
  };

  const [token, setToken] = useState(getToken())

  const saveToken = userToken => {
    sessionStorage.setItem('token', userToken)
    setToken(userToken)
  };

  return {
    setToken: saveToken,
    token
  }
}

// export function useOtp() {
//   const getOtp = () => {
//     const otpString = sessionStorage.getItem('otp')
//     const userOtp = otpString
//     return userOtp
//   };

//   const [otp, setOtp] = useState(getOtp())

//   const saveOtp = userOtp => {
//     sessionStorage.setItem('otp', userOtp)
//     setOtp(userOtp)
//   };

//   return {
//     setOtp: saveOtp,
//     otp
//   }
// }