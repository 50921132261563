import React, { useState } from "react";
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

async function loginUser(credentials) {
  return fetch(process.env.REACT_APP_BACKEND + '/auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
    .catch((error) => {
      console.log(error)
    })
}

export default function Login({ setToken }) {
  const [email, setUserName] = useState()
  const [password, setPassword] = useState()

  const handleSubmit = async e => {
    e.preventDefault();
    const token = await loginUser({
      email,
      password
    })
    // const token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c"

    if (!token.error) {
      setToken(token.token)
      sessionStorage.setItem('email', email)
      toast.success('Login Success', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        })
    }
    else{
      toast.error('Bad User or Password!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        })
    }
  }

  const { t } = useTranslation();

  return (
    <div>
      <div className="container ">
        <div className="row">
          <div className="col-3"></div>
          <div className="col">
            <br />
            <h1 className="h2">{t('records.login.s1title')}</h1>
            <form onSubmit={handleSubmit}>
              <label htmlFor="inputEmail" className="sr-only">{t('records.login.s1btn1txt')}</label>
              <input type="email" id="inputEmail" className="form-control" placeholder={t('records.login.s1btn1desc')} required onChange={e => setUserName(e.target.value)}></input>
              <label htmlFor="inputPassword" className="sr-only">{t('records.login.s1btn2txt')}</label>
              <input type="password" id="inputPassword" className="form-control" placeholder={t('records.login.s1btn2txt')} required onChange={e => setPassword(e.target.value)}></input>
              <br />
              <button type="submit" className="btn btn-primary btn-block" >Sign in</button>
              <ToastContainer/>
            </form>
            <br />
              <a href={process.env.REACT_APP_SIGNUP+'/recovery'} target="_blank" rel="noopener noreferrer">Forgot your password !</a>
          </div>
          <div className="col-4"></div>
        </div>
      </div>
    </div>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}