import { useParams } from 'react-router-dom';
import { useState } from "react";
import Error from "./Error";
import Footer from "./Footer";

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useTranslation } from 'react-i18next';

function EmergencyUser() {
  const { t } = useTranslation();
  let params = useParams();
  const [records, setRecords] = useState([]);
  const [nip, setNip] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsFetching(true);

    try {
      console.log(nip)
      const response = await fetch(process.env.REACT_APP_BACKEND + '/records/' + params.otp + '/' + nip, {
      });

      if (!response.ok) {
        return <Error />;
      }

      const data = await response.json();
      sessionStorage.setItem('records', JSON.stringify(data));
      setRecords(data);

    } catch (error) {
      return <Error />;
    } finally {
      setIsFetching(false);
      toast.error('Wrong NIP', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      })
    }
  };

  if (!(isFetching || (records && records.info))) {
    return (
      <div>
        <div className="container ">
          <div className="row">
            <div className="col-3"></div>
            <div className="col">
              <br />
              <h1 className="h2">{t('records.emergency.s1title')}</h1>
              <form onSubmit={handleSubmit}>
                <input type="text" value={nip} onChange={(e) => setNip(e.target.value)}
                  placeholder={t('records.emergency.s1desc')}
                  maxLength="4"
                  required
                />
                <br />
                <br />
                <button type="submit" className="btn btn-primary">{t('records.emergency.s1btn')}</button>
                <ToastContainer />
              </form>
            </div>
            <div className="col-4"></div>
          </div>
        </div>
      </div>
    );
  }

  return isFetching ? <div>Processing...</div> : <div >
    <main>
      {/* <div class="d-flex flex-column flex-shrink-0 p-3 bg-light" style={{ "width": "10%" }}>
        <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
        </a>
        <ul class="nav flex-column mb-auto">
          <li class="nav-item">
            {records.info['Personal Information']['Full Name']}
          </li>
        </ul>
      </div> */}


      <div className="container" style={{ "overflow-y": "auto" }}>
        <div className=" text-center justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom ">
          <h1 className="h2">{records.info['Personal Information']['Full Name']} - {t('json.sun.header')}</h1>
        </div>

        <div class="container">
          <div class="row">

            <div class="col-sm">
              <div class="list-group">

                <a href="#" class="list-group-item list-group-item-action flex-column align-items-start border-danger">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{t('json.sci.title')}</h5>
                  </div>
                  <p class="mb-1">
                    <div class="col-sm">
                      <ul>
                        <li>{t('json.spi.l1txt')} {records.info['Personal Information']['Full Name']}</li>
                        <li>{t('json.spi.l5txt')} {records.info['Personal Information']['Address']}</li>
                        <li>{t('json.spi.l7txt')} {records.info['Personal Information']['Phone Number']}</li>
                        {records.info['Emergency Contact'].map((contact, index) => (
                          <li>{contact.Name}, {contact.Relationship}, {contact.Phone}</li>
                        ))}
                        <li>{t('json.smii.l1txt')} {records.info['Medical Insurance Information']['Provider']}</li>
                        <li>{t('json.smii.l2txt')} {records.info['Medical Insurance Information']['Policy Number']}</li>
                      </ul>
                    </div>
                  </p>
                </a>

                <hr className="my-4" />

                <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{t('json.spi.title')}</h5>
                    <small class="text-muted">{t('json.spi.header')} {records.info.date}</small>
                  </div>
                  <p class="mb-1">
                    <div class="col-sm">
                      <ul>
                        <li>{t('json.spi.l1txt')} {records.info['Personal Information']['Full Name']}</li>
                        <li>{t('json.spi.l2txt')} {records.info['Personal Information']['Date of Birth']}</li>
                        <li>{t('json.spi.l3txt')} {records.info['Personal Information']['Gender']}</li>
                        <li>{t('json.spi.l4txt')} {records.info['Personal Information']['Blood Type']}</li>
                        <li>{t('json.spi.l5txt')} {records.info['Personal Information']['Address']}</li>
                        <li>{t('json.spi.l6txt')} {records.info['Personal Information']['Email']}</li>
                        <li>{t('json.spi.l7txt')} {records.info['Personal Information']['Phone Number']}</li>
                        <li>{t('json.spi.l8txt')} {records.info['Personal Information']['Photo']}</li>
                        <li>{t('json.spi.l9txt')} {records.info['Personal Information']['Ethnicity']}</li>
                        <li>{t('json.spi.l10txt')} {records.info['Personal Information']['Preferred Language']}</li>
                        <li>{t('json.spi.l11txt')} {records.info['Personal Information']['Nationality']}</li>
                        <li>{t('json.spi.l12txt')} {records.info['Personal Information']['ID TYPE']}</li>
                        <li>{t('json.spi.l13txt')} {records.info['Personal Information']['ID']}</li>
                      </ul>
                    </div>
                  </p>
                </a>

                <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{t('json.sec.title')}</h5>
                  </div>
                  <p class="mb-1">
                    <div class="col-sm">
                      <ul>
                        {records.info['Emergency Contact'].map((contact, index) => (
                          <li>{contact.Name}, {contact.Relationship}, {contact.Phone}</li>
                        ))}
                      </ul>
                    </div>
                  </p>
                </a>

                <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{t('json.smh.title')}</h5>
                  </div>
                  <p class="mb-1">
                    <div class="col-sm">
                      <ul>
                        <li>{t('json.smh.l1txt')} {records.info['Medical History']['Known Allergies']}</li>
                        <li>{t('json.smh.l2txt')} {records.info['Medical History']['Past Surgeries']}</li>
                        <li>{t('json.smh.l3txt')} {records.info['Medical History']['Chronic Conditions']}</li>
                        <li>{t('json.smh.l4txt')} {records.info['Medical History']['Family Medical History']}</li>
                        <li>{t('json.smh.l5txt')} {records.info['Medical History']['Vaccination History']}</li>
                        <li>{t('json.smh.l6txt')} {records.info['Medical History']['Previous Hospitalizations']}</li>
                      </ul>
                    </div>
                  </p>
                </a>


                <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{t('json.scm.title')}</h5>
                  </div>
                  <p class="mb-1">
                    <div class="col-sm">
                      <ul>
                        {records.info['Current Medications'].map((medications, index) => (
                          <li>{medications.Medication}, {medications.Dosage}, {medications.Frequency}</li>
                        ))}
                      </ul>
                    </div>
                  </p>
                </a>

                <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{t('json.scm2.title')}</h5>
                  </div>
                  <p class="mb-1">
                    <div class="col-sm">
                      <ul>
                        <li>{records.info['Contraindicated Medications']}</li>
                      </ul>
                    </div>
                  </p>
                </a>

                <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{t('json.sppc.title')}</h5>
                  </div>
                  <p class="mb-1">
                    <div class="col-sm">
                      <ul>
                        <li>{t('json.sppc.l1txt')} {records.info['Personal Physician Contact']['Name']}</li>
                        <li>{t('json.sppc.l2txt')} {records.info['Personal Physician Contact']['Phone']}</li>
                      </ul>
                    </div>
                  </p>
                </a>

                <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{t('json.smii.title')}</h5>
                  </div>
                  <p class="mb-1">
                    <div class="col-sm">
                      <ul>
                        <li>{t('json.smii.l1txt')} {records.info['Medical Insurance Information']['Provider']}</li>
                        <li>{t('json.smii.l2txt')} {records.info['Medical Insurance Information']['Policy Number']}</li>
                        <li>{t('json.smii.l3txt')}  {records.info['Medical Insurance Information']['Coverage']}</li>
                        <li>{t('json.smii.l4txt')} {records.info['Medical Insurance Information']['Policy Details']}</li>
                        <li>{t('json.smii.l5txt')} {records.info['Medical Insurance Information']['Insurance Contact']}</li>
                      </ul>
                    </div>
                  </p>
                </a>

                <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{t('json.sdn.title')}</h5>
                  </div>
                  <p class="mb-1">
                    <div class="col-sm">
                      <ul>
                        <li>{t('json.sdn.l1txt')} {records.info['Doctor\'s Notes']['Date of Visit']}</li>
                        <li>{t('json.sdn.l2txt')} {records.info['Doctor\'s Notes']['Reason for Visit']}</li>
                        <li>{t('json.sdn.l3txt')} {records.info['Doctor\'s Notes']['Recommendations']}</li>
                      </ul>
                    </div>
                  </p>
                </a>

                <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{t('json.sod.title')}</h5>
                  </div>
                  <p class="mb-1">
                    <div class="col-sm">
                      <ul>
                        <li>{t('json.sod.l1txt')} {records.info['Organ Donation']['Status']}</li>
                        <li>{t('json.sod.l2txt')} {records.info['Organ Donation']['Additional information']}</li>
                      </ul>
                    </div>
                  </p>
                </a>

                <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{t('json.sad.title')}</h5>
                  </div>
                  <p class="mb-1">
                    <div class="col-sm">
                      <ul>
                        <li>{records.info['Advance Directives']}</li>
                      </ul>
                    </div>
                  </p>
                </a>

                <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{t('json.san.title')}</h5>
                  </div>
                  <p class="mb-1">
                    <div class="col-sm">
                      <ul>
                        <li>{records.info['Additional Notes']}</li>
                      </ul>
                    </div>
                  </p>
                </a>

              </div>
            </div>
            <div class="col-sm">
            </div>
          </div>
        </div>
      </div>


    </main>
    <Footer />
  </div>;



}

export default EmergencyUser;
