import { Link } from 'react-router-dom';


const errorInfo = (
  <div className="">
  <pre>{`
  ,+++77777++=:,                    +=                      ,,++=7++=,,
 7~?7   +7I77 :,I777  I          77 7+77 7:        ,?777777??~,=+=~I7?,=77 I
=7I7I~7  ,77: ++:~+777777 7     +77=7 =7I7     ,I777= 77,:~7 +?7, ~7   ~ 777?
77+7I 777~,,=7~  ,::7=7: 7 77   77: 7 7 +77,7 I777~+777I=   =:,77,77  77 7,777,
= 7  ?7 , 7~,~  + 77 ?: :?777 +~77 77? I7777I7I7 777+77   =:, ?7   +7 777?
   77 ~I == ~77=77777~: I,+77?  7  7:?7? ?7 7 7 77 ~I   7I,,?7 I77~
    I 7=77~+77+?=:I+~77?     , I 7? 77 7   777~ +7 I+?7  +7~?777,77I
      =77 77= +7 7777         ,7 7?7:,??7     +7    7   77??+ 7777,
          =I, I 7+:77?         +7I7?7777 :             :7 7
             7I7I?77 ~         +7:77,     ~         +7,::7   7
            ,7~77?7? ?:         7+:77           77 :7777=
             ?77 +I7+,7         7~  7,+7  ,?       ?7?~?777:
                I777=7777 ~     77 :  77 =7+,    I77  777
                  +      ~?     , + 7    ,, ~I,  = ? ,
                                 77:I+
                                 ,7
                                  :777
                                     :

`}
  </pre>
  </div>
)

function Error() {
  return (

    <div >

      <main>
        <div class="d-flex flex-column flex-shrink-0 p-3 bg-light" style={{ "width": "200px" }}>
          <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
          </a>
          <ul class="nav flex-column mb-auto">
            <li class="nav-item">
              <Link class="nav-link active" to="/home/overview">Overview</Link >
            </li>
            <li class="nav-item">
              <Link class="nav-link active" to="/home/about">About</Link >
            </li>
            <li class="nav-item">
              <a href="/home/faq" class="nav-link active" aria-current="page">
                FAQ
              </a>
            </li>
          </ul>

        </div>
        <div className="container">
          <div className="text-center justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom ">
            <h1 className="h2">Error Page</h1>
          </div>
          {errorInfo}
        </div>


      </main>
    </div>

  );
}

export default Error;