import { Link } from 'react-router-dom';
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';


function textInfo(t) {
  return (
    <div className="container" style={{"overflow-y": "auto"}}>
      <div className="text-center justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">{t('home.faq.s1title')}</h1>
      </div>

      <div className="faq-list">
        <ol>
          <li>
            <strong>{t('home.faq.s1l1txt')}</strong>
            <p>{t('home.faq.s1l1desc')}</p>
          </li>
          <li>
            <strong>{t('home.faq.s1l2txt')}</strong>
            <p>{t('home.faq.s1l1desc')}</p>
          </li>
          <li>
            <strong>{t('home.faq.s1l3txt')}</strong>
            <p>{t('home.faq.s2l1desc')}</p>
          </li>
          <li>
            <strong>{t('home.faq.s1l3txt')}</strong>
            <p>{t('home.faq.s1l1desc')}</p>
          </li>
          <li>
            <strong>{t('home.faq.s1l4txt')}</strong>
            <p>{t('home.faq.s1l4desc')}</p>
          </li>
          <li>
            <strong>{t('home.faq.s1l5txt')}</strong>
            <p>{t('home.faq.s1l5desc')}</p>
          </li>
          <li>
            <strong>{t('home.faq.s1l6txt')}</strong>
            <p>{t('home.faq.s1l6desc')}</p>
          </li>
          <li>
            <strong>{t('home.faq.s1l7txt')}</strong>
            <p>{t('home.faq.s1l7desc')}</p>
          </li>
          <li>
            <strong>{t('home.faq.s1l8txt')}</strong>
            <p>{t('home.faq.s1l8desc')}</p>
          </li>
          <li>
            <strong>{t('home.faq.s1l9txt')}</strong>
            <p>{t('home.faq.s1l9desc')}</p>
          </li>
          <li>
            <strong>{t('home.faq.s1l10txt')}</strong>
            <p>{t('home.faq.s1l10desc')}</p>
          </li>
        </ol>
      </div>
      <br />
    </div>

  )
}


function Faq() {
  const { t } = useTranslation();

  return (
    <div >

      <main>
        <div class="d-flex flex-column flex-shrink-0 p-3 bg-light" style={{ minWidth: "120px", maxWidth: "20%" }}>
          <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
          </a>
          <ul class="nav flex-column mb-auto">
            <li class="nav-item">
              <Link class="nav-link active" to="/home/overview" >
                {t('barside.overview')}
              </Link >
            </li>
            <li class="nav-item">
              <Link class="nav-link active" to="/home/about">
                {t('barside.about')}
              </Link >
            </li>
            <li class="nav-item">
              <Link class="nav-link active" to="/home/faq"> {t('barside.faq')}</Link >
            </li>
          </ul>
        </div>
        {textInfo(t)}
      </main>

      <Footer />
    </div>
  );
}

export default Faq;
